/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';

export const categoriesOptions = [
  {
    key: 'content_creating',
    label: <FormattedMessage id="SearchFilters.categoryContentCreating" />,
    value: 'content_creating',
  },
  {
    key: 'social_media_content',
    label: <FormattedMessage id="SearchFilters.categorySocialMediaContent" />,
    value: 'social_media_content',
  },
  {
    key: 'articles_n_blog_content',
    label: <FormattedMessage id="SearchFilters.categoryArticlesAndBlogContent" />,
    value: 'articles_n_blog_content',
  },
  {
    key: 'ugc_content',
    label: <FormattedMessage id="SearchFilters.categoryUgcContent" />,
    value: 'ugc_content',
  },
  {
    key: 'digital_marketing',
    label: <FormattedMessage id="SearchFilters.categoryDigitalMarketing" />,
    value: 'digital_marketing',
  },
  {
    key: 'seo',
    label: <FormattedMessage id="SearchFilters.categorySEO" />,
    value: 'seo',
  },
  {
    key: 'search_engine_marketing',
    label: <FormattedMessage id="SearchFilters.categorySearchEngineMarketing" />,
    value: 'search_engine_marketing',
  },
  {
    key: 'social_media_advertising',
    label: <FormattedMessage id="SearchFilters.categorySocialMediaAdvertising" />,
    value: 'social_media_advertising',
  },
  {
    key: 'email_marketing',
    label: <FormattedMessage id="SearchFilters.categoryEmailMarketing" />,
    value: 'email_marketing',
  },
  {
    key: 'marketing_strategy',
    label: <FormattedMessage id="SearchFilters.categoryMarketingStrategy" />,
    value: 'marketing_strategy',
  },
  {
    key: 'ecommerce_n_websites',
    label: <FormattedMessage id="SearchFilters.categoryEcommerceAndWebsites" />,
    value: 'ecommerce_n_websites',
  },
  {
    key: 'website_development',
    label: <FormattedMessage id="SearchFilters.categoryWebsiteDevelopment" />,
    value: 'website_development',
  },
  {
    key: 'ecommerce_development',
    label: <FormattedMessage id="SearchFilters.categoryEcommerceDevelopment" />,
    value: 'ecommerce_development',
  },
  {
    key: 'photography_n_video',
    label: <FormattedMessage id="SearchFilters.categoryPhotoGraphyAndVideo" />,
    value: 'photography_n_video',
  },
  {
    key: 'editing',
    label: <FormattedMessage id="SearchFilters.categoryEditing" />,
    value: 'editing',
  },
  {
    key: 'product_photography',
    label: <FormattedMessage id="SearchFilters.categoryProductPhotography" />,
    value: 'product_photography',
  },
  {
    key: 'photoshoots',
    label: <FormattedMessage id="SearchFilters.categoryPhotoshoots" />,
    value: 'photoshoots',
  },
  {
    key: '3d_modeling',
    label: <FormattedMessage id="SearchFilters.category3dModeling" />,
    value: '3d_modeling',
  },
  {
    key: 'video_production',
    label: <FormattedMessage id="SearchFilters.categoryVideoProduction" />,
    value: 'video_production',
  },
  {
    key: 'programming_n_web_development',
    label: <FormattedMessage id="SearchFilters.categoryProgrammingAndWebDevelopment" />,
    value: 'programming_n_web_development',
  },
  {
    key: 'application_development',
    label: <FormattedMessage id="SearchFilters.categoryApplicationDevelopment" />,
    value: 'application_development',
  },
  {
    key: 'front_end_development',
    label: <FormattedMessage id="SearchFilters.categoryFrontEndDevelopment" />,
    value: 'front_end_development',
  },
  {
    key: 'back_end_development',
    label: <FormattedMessage id="SearchFilters.categoryBackEndDevelopment" />,
    value: 'back_end_development',
  },
  {
    key: 'user_testing',
    label: <FormattedMessage id="SearchFilters.categoryUserTesting" />,
    value: 'user_testing',
  },
  {
    key: 'writing_and_translation',
    label: <FormattedMessage id="SearchFilters.categoryWritingAndTranslation" />,
    value: 'writing_and_translation',
  },
  {
    key: 'translations',
    label: <FormattedMessage id="SearchFilters.categoryTranslations" />,
    value: 'translations',
  },
  {
    key: 'sales_copy',
    label: <FormattedMessage id="SearchFilters.categorySalesCopy" />,
    value: 'sales_copy',
  },
  {
    key: 'proofreading',
    label: <FormattedMessage id="SearchFilters.categoryProofreading" />,
    value: 'proofreading',
  },
  {
    key: 'graphic_design',
    label: <FormattedMessage id="SearchFilters.categoryGraphicDesign" />,
    value: 'graphic_design',
  },
  {
    key: 'logos',
    label: <FormattedMessage id="SearchFilters.categoryLogos" />,
    value: 'logos',
  },
  {
    key: 'corporate_identity',
    label: <FormattedMessage id="SearchFilters.categoryCorporateIdentity" />,
    value: 'corporate_identity',
  },
  {
    key: 'illustration',
    label: <FormattedMessage id="SearchFilters.categoryIllustrations" />,
    value: 'illustration',
  },
  {
    key: 'printout',
    label: <FormattedMessage id="SearchFilters.categoryPrintDesign" />,
    value: 'printout',
  },
  {
    key: 'ui_and_ux_design',
    label: <FormattedMessage id="SearchFilters.categoryUIandUXDesign" />,
    value: 'ui_and_ux_design',
  },
  {
    key: 'audio',
    label: <FormattedMessage id="SearchFilters.categoryAudio" />,
    value: 'audio',
  },
  {
    key: 'ai_n_machine_learning',
    label: <FormattedMessage id="SearchFilters.categoryAIAndMachineLearning" />,
    value: 'ai_n_machine_learning',
  },
  {
    key: 'virtual_assistant',
    label: <FormattedMessage id="SearchFilters.categoryVirtualAssistant" />,
    value: 'virtual_assistant',
  },
  {
    key: 'influencer_marketing',
    label: <FormattedMessage id="SearchFilters.categoryInfluencerMarketing" />,
    value: 'influencer_marketing',
  },
  {
    key: 'consulting',
    label: <FormattedMessage id="SearchFilters.categoryConsulting" />,
    value: 'consulting',
  },
];

export const buyerLookingForOptions = [
  {
    key: 'content_creating',
    label: <FormattedMessage id="SearchFilters.categoryContentCreating" />,
    value: 'content_creating',
  },
  {
    key: 'ecommerce_n_websites',
    label: <FormattedMessage id="SearchFilters.categoryEcommerceAndWebsites" />,
    value: 'ecommerce_n_websites',
  },
  {
    key: 'digital_marketing',
    label: <FormattedMessage id="SearchFilters.categoryDigitalMarketing" />,
    value: 'digital_marketing',
  },
  {
    key: 'graphic_design',
    label: <FormattedMessage id="SearchFilters.categoryGraphicDesign" />,
    value: 'graphic_design',
  },
  {
    key: 'photography_n_video',
    label: <FormattedMessage id="SearchFilters.categoryPhotoGraphyAndVideo" />,
    value: 'photography_n_video',
  },
  {
    key: 'programming_n_web_development',
    label: <FormattedMessage id="SearchFilters.categoryProgrammingAndWebDevelopment" />,
    value: 'programming_n_web_development',
  },
  {
    key: 'other_categories',
    label: <FormattedMessage id="SearchFilters.categoryOtherCategories" />,
    value: 'other_categories',
  },
];

export const buyerDurationOptions = [
  {
    key: '0-1',
    label: <FormattedMessage id="SearchFilters.durationLessThanAMonth" />,
    value: '0-1',
  },
  {
    key: '1-3',
    label: <FormattedMessage id="SearchFilters.durationOneToThreeMonths" />,
    value: '1-3',
  },
  {
    key: '3-6',
    label: <FormattedMessage id="SearchFilters.durationThreeToSixMonths" />,
    value: '3-6',
  },
  {
    key: '6+',
    label: <FormattedMessage id="SearchFilters.durationMoreThanSixMonths" />,
    value: '6+',
  },
];

export const levelOfCommitmentOptions = [
  {
    key: 'full_time',
    label: <FormattedMessage id="SearchFilters.levelOfCommitmentFullTime" />,
    value: 'full_time',
  },
  {
    key: 'part_time',
    label: <FormattedMessage id="SearchFilters.levelOfCommitmentPartTime" />,
    value: 'part_time',
  },
  {
    key: 'project_based',
    label: <FormattedMessage id="SearchFilters.levelOfCommitmentProjectBased" />,
    value: 'project_based',
  },
  {
    key: 'hourly',
    label: <FormattedMessage id="SearchFilters.levelOfCommitmentHourly" />,
    value: 'hourly',
  },
];

export const urgencyOptions = [
  {
    key: 'urgent',
    label: <FormattedMessage id="SearchFilters.urgencyUrgent" />,
    value: 'urgent',
  },
  {
    key: 'within_a_week',
    label: <FormattedMessage id="SearchFilters.urgencyWithinAWeek" />,
    value: 'normal',
  },
  {
    key: 'within_a_month',
    label: <FormattedMessage id="SearchFilters.urgencyWithinAMonth" />,
    value: 'flexible',
  },
  {
    key: 'not_urgent',
    label: <FormattedMessage id="SearchFilters.urgencyNotUrgent" />,
    value: 'not_urgent',
  },
];

export const filters = [
  // {
  //   id: 'price',
  //   label: 'Hinta',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 5000,
  //     step: 5,
  //   },
  // },
  {
    id: 'keyword',
    label: <FormattedMessage id="SortFilters.sortConfigKeyword" />,
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: <FormattedMessage id="SortFilters.sortConfigCategory" />,
    type: 'SelectCategoryFilter',
    group: 'primary',
    queryParamNames: ['pub_categoryNodes'],
    config: {
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'content_creating',
          label: <FormattedMessage id="SearchFilters.categoryContentCreating" />,
          parent: '',
          id: 1,
          name: 'ContentCreating',
          path: '/kategoriat/sisallontuotanto',
          parentOrder: 1,
        },
        {
          key: 'social_media_content',
          label: <FormattedMessage id="SearchFilters.categorySocialMediaContent" />,
          parent: 'content_creating',
          id: 2,
          path: '/kategoriat/sisallontuotanto/sosiaalisen-median-sisallot',
          name: 'SocialMediaContent',
        },
        {
          key: 'articles_n_blog_content',
          label: <FormattedMessage id="SearchFilters.categoryArticlesAndBlogContent" />,
          parent: 'content_creating',
          id: 3,
          path: '/kategoriat/sisallontuotanto/artikkelit-ja-blogikirjoitukset',
          name: 'ArticlesAndBlogContent',
        },
        {
          key: 'ugc_content',
          label: <FormattedMessage id="SearchFilters.categoryUgcContent" />,
          parent: 'content_creating',
          id: 48,
          path: '/kategoriat/sisallontuotanto/ugc-sisallot',
          name: 'UgcContent',
        },
        {
          key: 'other_content_creating',
          label: <FormattedMessage id="SearchFilters.categoryOther" />,
          parent: 'content_creating',
          id: 4,
          path: '/kategoriat/sisallontuotanto/muu-sisallontuotanto',
          name: 'OtherContentCreating',
        },
        {
          key: 'digital_marketing',
          label: <FormattedMessage id="SearchFilters.categoryDigitalMarketing" />,
          parent: '',
          id: 5,
          path: '/kategoriat/digitaalinen-markkinointi',
          name: 'DigitalMarketing',
          parentOrder: 4,
        },
        {
          key: 'seo',
          label: <FormattedMessage id="SearchFilters.categorySEO" />,
          parent: 'digital_marketing',
          id: 6,
          path: '/kategoriat/digitaalinen-markkinointi/hakukoneoptimointi',
          name: 'Seo',
        },
        {
          key: 'search_engine_marketing',
          label: <FormattedMessage id="SearchFilters.categorySearchEngineMarketing" />,
          parent: 'digital_marketing',
          id: 7,
          path: '/kategoriat/digitaalinen-markkinointi/google-mainonta',
          name: 'SearchEngineMarketing',
        },
        {
          key: 'social_media_advertising',
          label: <FormattedMessage id="SearchFilters.categorySocialMediaAdvertising" />,
          parent: 'digital_marketing',
          id: 8,
          path: '/kategoriat/digitaalinen-markkinointi/somemainonta',
          name: 'SocialMediaAdvertising',
        },
        {
          key: 'email_marketing',
          label: <FormattedMessage id="SearchFilters.categoryEmailMarketing" />,
          parent: 'digital_marketing',
          id: 9,
          path: '/kategoriat/digitaalinen-markkinointi/sahkoposti-markkinointi',
          name: 'EmailMarketing',
        },
        {
          key: 'marketing_strategy',
          label: <FormattedMessage id="SearchFilters.categoryMarketingStrategy" />,
          parent: 'digital_marketing',
          id: 10,
          path: '/kategoriat/digitaalinen-markkinointi/markkinointistrategia',
          name: 'MarketingStrategy',
        },
        {
          key: 'other_digital_marketing',
          label: <FormattedMessage id="SearchFilters.categoryOther" />,
          parent: 'digital_marketing',
          id: 11,
          path: '/kategoriat/digitaalinen-markkinointi/muu-digitaalinen-markkinointi',
          name: 'OtherDigitalMarketing',
        },
        {
          key: 'ecommerce_n_websites',
          label: <FormattedMessage id="SearchFilters.categoryEcommerceAndWebsites" />,
          parent: '',
          id: 12,
          path: '/kategoriat/verkkokaupat-ja-verkkosivut',
          name: 'EcommerceAndWebsites',
          parentOrder: 3,
        },
        {
          key: 'website_development',
          label: <FormattedMessage id="SearchFilters.categoryWebsiteDevelopment" />,
          parent: 'ecommerce_n_websites',
          id: 13,
          path: '/kategoriat/verkkokaupat-ja-verkkosivut/verkkosivujen-suunnittelu',
          name: 'WebsiteDevelopment',
        },
        {
          key: 'ecommerce_development',
          label: <FormattedMessage id="SearchFilters.categoryEcommerceDevelopment" />,
          parent: 'ecommerce_n_websites',
          id: 14,
          path: '/kategoriat/verkkokaupat-ja-verkkosivut/verkkokaupan-suunnittelu',
          name: 'EcommerceDevelopment',
        },
        {
          key: 'other_ecommerce_n_websites',
          label: <FormattedMessage id="SearchFilters.categoryOther" />,
          parent: 'ecommerce_n_websites',
          id: 15,
          path: '/kategoriat/verkkokaupat-ja-verkkosivut/muu-verkkokaupat-ja-verkkosivut',
          name: 'OtherEcommerceAndWebsites',
        },
        {
          key: 'photography_n_video',
          label: <FormattedMessage id="SearchFilters.categoryPhotoGraphyAndVideo" />,
          parent: '',
          id: 16,
          path: '/kategoriat/kuva-ja-video',
          name: 'PhotographyAndVideo',
          parentOrder: 5,
        },
        {
          key: 'editing',
          label: <FormattedMessage id="SearchFilters.categoryEditing" />,
          parent: 'photography_n_video',
          id: 17,
          path: '/kategoriat/kuva-ja-video/editointi',
          name: 'Editing',
        },
        {
          key: 'product_photography',
          label: <FormattedMessage id="SearchFilters.categoryProductPhotography" />,
          parent: 'photography_n_video',
          id: 18,
          path: '/kategoriat/kuva-ja-video/tuotekuvaus',
          name: 'ProductPhotography',
        },
        {
          key: 'photoshoots',
          label: <FormattedMessage id="SearchFilters.categoryPhotoshoots" />,
          parent: 'photography_n_video',
          id: 19,
          path: '/kategoriat/kuva-ja-video/valokuvaus',
          name: 'Photoshoots',
        },
        {
          key: '3d_modeling',
          label: <FormattedMessage id="SearchFilters.category3dModeling" />,
          parent: 'photography_n_video',
          id: 20,
          path: '/kategoriat/kuva-ja-video/3d-mallinnus',
          name: '3dModeling',
        },
        {
          key: 'video_production',
          label: <FormattedMessage id="SearchFilters.categoryVideoProduction" />,
          parent: 'photography_n_video',
          id: 21,
          path: '/kategoriat/kuva-ja-video/videotuotanto',
          name: 'VideoProduction',
        },
        {
          key: 'other_photography_n_video',
          label: <FormattedMessage id="SearchFilters.categoryOther" />,
          parent: 'photography_n_video',
          id: 22,
          path: '/kategoriat/kuva-ja-video/muu-videotuotanto',
          name: 'OtherPhotographyAndVideo',
        },
        {
          key: 'programming_n_web_development',
          label: <FormattedMessage id="SearchFilters.categoryProgrammingAndWebDevelopment" />,
          parent: '',
          id: 23,
          path: '/kategoriat/ohjelmointi-ja-webkehitys',
          name: 'ProgrammingAndWebDevelopment',
          parentOrder: 6,
        },
        {
          key: 'application_development',
          label: <FormattedMessage id="SearchFilters.categoryApplicationDevelopment" />,
          parent: 'programming_n_web_development',
          id: 24,
          path: '/kategoriat/ohjelmointi-ja-webkehitys/sovelluskehitys',
          name: 'ApplicationDevelopment',
        },
        {
          key: 'front_end_development',
          label: <FormattedMessage id="SearchFilters.categoryFrontEndDevelopment" />,
          parent: 'programming_n_web_development',
          id: 25,
          path: '/kategoriat/ohjelmointi-ja-webkehitys/front-end-kehitys',
          name: 'FrontEndDevelopment',
        },
        {
          key: 'back_end_development',
          label: <FormattedMessage id="SearchFilters.categoryBackEndDevelopment" />,
          parent: 'programming_n_web_development',
          id: 26,
          path: '/kategoriat/ohjelmointi-ja-webkehitys/back-end-kehitys',
          name: 'BackEndDevelopment',
        },
        {
          key: 'user_testing',
          label: <FormattedMessage id="SearchFilters.categoryUserTesting" />,
          parent: 'programming_n_web_development',
          id: 27,
          path: '/kategoriat/ohjelmointi-ja-webkehitys/kayttajatestaus',
          name: 'UserTesting',
        },
        {
          key: 'other_programming_n_web_development',
          label: <FormattedMessage id="SearchFilters.categoryOther" />,
          parent: 'programming_n_web_development',
          id: 28,
          path: '/kategoriat/ohjelmointi-ja-webkehitys/muu-ohjelmointi-ja-webkehitys',
          name: 'OtherProgrammingAndWebDevelopment',
        },
        {
          key: 'writing_and_translation',
          label: <FormattedMessage id="SearchFilters.categoryWritingAndTranslation" />,
          parent: '',
          id: 29,
          path: '/kategoriat/kirjoittaminen-ja-kaannokset',
          name: 'WritingAndTranslation',
          parentOrder: 7,
        },
        {
          key: 'translations',
          label: <FormattedMessage id="SearchFilters.categoryTranslations" />,
          parent: 'writing_and_translation',
          id: 30,
          path: '/kategoriat/kirjoittaminen-ja-kaannokset/kaannokset',
          name: 'Translations',
        },
        {
          key: 'sales_copy',
          label: <FormattedMessage id="SearchFilters.categorySalesCopy" />,
          parent: 'writing_and_translation',
          id: 31,
          path: '/kategoriat/kirjoittaminen-ja-kaannokset/myyntitekstit',
          name: 'SalesCopy',
        },
        {
          key: 'proofreading',
          label: <FormattedMessage id="SearchFilters.categoryProofreading" />,
          parent: 'writing_and_translation',
          id: 100,
          path: '/kategoriat/kirjoittaminen-ja-kaannokset/oikoluku',
          name: 'Proofreading',
        },
        {
          key: 'other_writing_and_translation',
          label: <FormattedMessage id="SearchFilters.categoryOther" />,
          parent: 'writing_and_translation',
          id: 32,
          path: '/kategoriat/kirjoittaminen-ja-kaannokset/muu-kirjoittaminen-ja-kaannokset',
          name: 'OtherWritingAndTranslation',
        },
        {
          key: 'graphic_design',
          label: <FormattedMessage id="SearchFilters.categoryGraphicDesign" />,
          parent: '',
          id: 33,
          path: '/kategoriat/graafinen-suunnittelu',
          name: 'GraphicDesign',
          parentOrder: 2,
        },
        {
          key: 'logos',
          label: <FormattedMessage id="SearchFilters.categoryLogos" />,
          parent: 'graphic_design',
          id: 34,
          path: '/kategoriat/graafinen-suunnittelu/logot',
          name: 'Logos',
        },
        {
          key: 'corporate_identity',
          label: <FormattedMessage id="SearchFilters.categoryCorporateIdentity" />,
          parent: 'graphic_design',
          id: 35,
          path: '/kategoriat/graafinen-suunnittelu/brandi-tai-yritysilme',
          name: 'CorporateIdentity',
        },
        {
          key: 'illustration',
          label: <FormattedMessage id="SearchFilters.categoryIllustrations" />,
          parent: 'graphic_design',
          id: 36,
          path: '/kategoriat/graafinen-suunnittelu/kuvitukset',
          name: 'Illustrations',
        },
        {
          key: 'printout',
          label: <FormattedMessage id="SearchFilters.categoryPrintDesign" />,
          parent: 'graphic_design',
          id: 37,
          path: '/kategoriat/graafinen-suunnittelu/printti',
          name: 'PrintDesign',
        },
        {
          key: 'ui_and_ux_design',
          label: <FormattedMessage id="SearchFilters.categoryUIandUXDesign" />,
          parent: 'graphic_design',
          id: 38,
          path: '/kategoriat/graafinen-suunnittelu/ui-ja-ux-suunnittelu',
          name: 'UiUxDesign',
        },
        {
          key: 'other_graphic_design',
          label: <FormattedMessage id="SearchFilters.categoryOther" />,
          parent: 'graphic_design',
          id: 39,
          path: '/kategoriat/graafinen-suunnittelu/muu-graafinensuunnittelu',
          name: 'OtherGraphicDesign',
        },
        {
          key: 'other_categories',
          label: <FormattedMessage id="SearchFilters.categoryOtherCategories" />,
          parent: '',
          id: 43,
          path: '/kategoriat/muut-kategoriat',
          name: 'OtherCategories',
          parentOrder: 9,
        },
        {
          key: 'ai_n_machine_learning',
          label: <FormattedMessage id="SearchFilters.categoryAIAndMachineLearning" />,
          parent: 'other_categories',
          id: 44,
          path: '/kategoriat/muut-kategoriat/tekoaly-ja-koneoppiminen',
          name: 'AIAndMachineLearning',
        },
        {
          key: 'virtual_assistant',
          label: <FormattedMessage id="SearchFilters.categoryVirtualAssistant" />,
          parent: 'other_categories',
          id: 45,
          path: '/kategoriat/muut-kategoriat/virtuaaliassistentti',
          name: 'VirtualAssistant',
        },
        {
          key: 'influencer_marketing',
          label: <FormattedMessage id="SearchFilters.categoryInfluencerMarketing" />,
          parent: 'other_categories',
          id: 46,
          path: '/kategoriat/muut-kategoriat/vaikuttajamarkkinointi',
          name: 'InfluencerMarketing',
        },
        {
          key: 'consulting',
          label: <FormattedMessage id="SearchFilters.categoryConsulting" />,
          parent: 'other_categories',
          id: 47,
          path: '/kategoriat/muut-kategoriat/konsultointi',
          name: 'Consulting',
        },
        {
          key: 'audio',
          label: <FormattedMessage id="SearchFilters.categoryAudio" />,
          parent: 'other_categories',
          id: 40,
          path: '/kategoriat/muut-kategoriat/aani',
          name: 'Audio',
        },
      ],
    },
  },
  {
    id: 'languages',
    label: <FormattedMessage id="SortFilters.sortConfigLanguages" />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_languages'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'finnish',
          label: <FormattedMessage id="SearchLanguages.languageFinnish" />,
          value: 'finnish',
        },
        {
          key: 'english',
          label: <FormattedMessage id="SearchLanguages.languageEnglish" />,
          value: 'english',
        },
        {
          key: 'swedish',
          label: <FormattedMessage id="SearchLanguages.languageSwedish" />,
          value: 'swedish',
        },
        {
          key: 'arabic',
          label: <FormattedMessage id="SearchLanguages.languageArabic" />,
          value: 'arabic',
        },
        {
          key: 'chinese',
          label: <FormattedMessage id="SearchLanguages.languageChinese" />,
          value: 'chinese',
        },
        {
          key: 'danish',
          label: <FormattedMessage id="SearchLanguages.languageDanish" />,
          value: 'danish',
        },
        {
          key: 'dutch',
          label: <FormattedMessage id="SearchLanguages.languageDutch" />,
          value: 'dutch',
        },
        {
          key: 'estonian',
          label: <FormattedMessage id="SearchLanguages.languageEstonian" />,
          value: 'estonian',
        },
        {
          key: 'french',
          label: <FormattedMessage id="SearchLanguages.languageFrench" />,
          value: 'french',
        },
        {
          key: 'german',
          label: <FormattedMessage id="SearchLanguages.languageGerman" />,
          value: 'german',
        },
        {
          key: 'hindi',
          label: <FormattedMessage id="SearchLanguages.languageHindi" />,
          value: 'hindi',
        },
        {
          key: 'italian',
          label: <FormattedMessage id="SearchLanguages.languageItalian" />,
          value: 'italian',
        },
        {
          key: 'japanese',
          label: <FormattedMessage id="SearchLanguages.languageJapanese" />,
          value: 'japanese',
        },
        {
          key: 'korean',
          label: <FormattedMessage id="SearchLanguages.languageKorean" />,
          value: 'korean',
        },
        {
          key: 'norwegian',
          label: <FormattedMessage id="SearchLanguages.languageNorwegian" />,
          value: 'norwegian',
        },
        {
          key: 'polish',
          label: <FormattedMessage id="SearchLanguages.languagePolish" />,
          value: 'polish',
        },
        {
          key: 'portuguese',
          label: <FormattedMessage id="SearchLanguages.languagePortuguese" />,
          value: 'portugese',
        },
        {
          key: 'russian',
          label: <FormattedMessage id="SearchLanguages.languageRussian" />,
          value: 'russian',
        },
        {
          key: 'spanish',
          label: <FormattedMessage id="SearchLanguages.languageSpanish" />,
          value: 'spanish',
        },
        {
          key: 'turkish',
          label: <FormattedMessage id="SearchLanguages.languageTurkish" />,
          value: 'turkish',
        },
      ],
    },
  },
  {
    id: 'filters',
    label: <FormattedMessage id="SortFilters.sortConfigFilters" />,
    type: 'SelectMultipleFilterAlternative',
    group: 'primary',
    queryParamNames: ['pub_languages'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      options: [
        {
          key: 'finnish',
          label: <FormattedMessage id="SearchLanguages.languageFinnish" />,
          value: 'finnish',
        },
        {
          key: 'english',
          label: <FormattedMessage id="SearchLanguages.languageEnglish" />,
          value: 'english',
        },
        {
          key: 'swedish',
          label: <FormattedMessage id="SearchLanguages.languageSwedish" />,
          value: 'swedish',
        },
        {
          key: 'arabic',
          label: <FormattedMessage id="SearchLanguages.languageArabic" />,
          value: 'arabic',
        },
        {
          key: 'chinese',
          label: <FormattedMessage id="SearchLanguages.languageChinese" />,
          value: 'chinese',
        },
        {
          key: 'danish',
          label: <FormattedMessage id="SearchLanguages.languageDanish" />,
          value: 'danish',
        },
        {
          key: 'dutch',
          label: <FormattedMessage id="SearchLanguages.languageDutch" />,
          value: 'dutch',
        },
        {
          key: 'estonian',
          label: <FormattedMessage id="SearchLanguages.languageEstonian" />,
          value: 'estonian',
        },
        {
          key: 'french',
          label: <FormattedMessage id="SearchLanguages.languageFrench" />,
          value: 'french',
        },
        {
          key: 'german',
          label: <FormattedMessage id="SearchLanguages.languageGerman" />,
          value: 'german',
        },
        {
          key: 'hindi',
          label: <FormattedMessage id="SearchLanguages.languageHindi" />,
          value: 'hindi',
        },
        {
          key: 'italian',
          label: <FormattedMessage id="SearchLanguages.languageItalian" />,
          value: 'italian',
        },
        {
          key: 'japanese',
          label: <FormattedMessage id="SearchLanguages.languageJapanese" />,
          value: 'japanese',
        },
        {
          key: 'korean',
          label: <FormattedMessage id="SearchLanguages.languageKorean" />,
          value: 'korean',
        },
        {
          key: 'norwegian',
          label: <FormattedMessage id="SearchLanguages.languageNorwegian" />,
          value: 'norwegian',
        },
        {
          key: 'polish',
          label: <FormattedMessage id="SearchLanguages.languagePolish" />,
          value: 'polish',
        },
        {
          key: 'portuguese',
          label: <FormattedMessage id="SearchLanguages.languagePortuguese" />,
          value: 'portugese',
        },
        {
          key: 'russian',
          label: <FormattedMessage id="SearchLanguages.languageRussian" />,
          value: 'russian',
        },
        {
          key: 'spanish',
          label: <FormattedMessage id="SearchLanguages.languageSpanish" />,
          value: 'spanish',
        },
        {
          key: 'turkish',
          label: <FormattedMessage id="SearchLanguages.languageTurkish" />,
          value: 'turkish',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: false,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    {
      key: 'meta_listingTier, createdAt',
      label: <FormattedMessage id="SortFilters.sortConfigRecommended" />,
    },
    { key: 'createdAt', label: <FormattedMessage id="SortFilters.sortConfigNewest" /> },
    { key: '-createdAt', label: <FormattedMessage id="SortFilters.sortConfigOldest" /> },
    { key: '-price', label: <FormattedMessage id="SortFilters.sortConfigLowestPrice" /> },
    { key: 'price', label: <FormattedMessage id="SortFilters.sortConfigHighestPrice" /> },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: <FormattedMessage id="SortFilters.sortConfigRelevance" />,
      longLabel: <FormattedMessage id="SortFilters.sortConfigRelevanceLong" />,
    },
  ],
};

export const industryOptions = [
  {
    key: 'goverment-and-public-sector',
    value: 'goverment-and-public-sector',
    label: 'Hallitus & Julkinen sektori',
  },
  {
    key: 'hr-and-business-services',
    value: 'hr-and-business-services',
    label: 'Hr & Yrityspalvelut',
  },
  {
    key: 'tech-and-it',
    value: 'tech-and-it',
    label: 'IT & Teknologia',
  },
  {
    key: 'real-estate',
    value: 'real-estate',
    label: 'Kiinteistöt',
  },
  {
    key: 'education',
    value: 'education',
    label: 'Koulutus',
  },
  {
    key: 'transportation-and-warehousing',
    value: 'transportation-and-warehousing',
    label: 'Kuljetus & Varastointi',
  },
  {
    key: 'legal',
    value: 'legal',
    label: 'Laki',
  },
  {
    key: 'supply-chain-and-logistics',
    value: 'supply-chain-and-logistics',
    label: 'Logistiikka',
  },
  {
    key: 'marketing-and-advertising',
    value: 'marketing-and-advertising',
    label: 'Markkinointi',
  },
  {
    key: 'travel-and-hospitality',
    value: 'travel-and-hospitality',
    label: 'Matkailu',
  },
  {
    key: 'media-and-entertainment',
    value: 'media-and-entertainment',
    label: 'Media & Viihde',
  },
  {
    key: 'fashion-and-beauty',
    value: 'fashion-and-beauty',
    label: 'Muoti & Kauneus',
  },
  {
    key: 'sales',
    value: 'sales',
    label: 'Myynti',
  },
  {
    key: 'finance-and-accounting',
    value: 'finance-and-accounting',
    label: 'Rahoitus & Kirjanpito',
  },
  {
    key: 'food-and-beverage',
    value: 'food-and-beverage',
    label: 'Ruoka & Juoma',
  },
  {
    key: 'software-development',
    value: 'software-development',
    label: 'Sovelluskehitys',
  },
  {
    key: 'art-and-desing',
    value: 'art-and-desing',
    label: 'Taide & Suunnittelu',
  },
  {
    key: 'health-and-fitness',
    value: 'health-and-fitness',
    label: 'Terveys & Hyvinvointi',
  },
  {
    key: 'sports-and-recreationt',
    value: 'sports-and-recreationt',
    label: 'Urheilu & Vapaa-aika',
  },
  {
    key: 'retail-and-consumer-goods',
    value: 'retail-and-consumer-goods',
    label: 'Vähittäiskauppa',
  },
  {
    key: 'other',
    value: 'other',
    label: 'Muu',
  },
];

export const skillOptions = [
  {
    key: 'net',
    label: <FormattedMessage id="SkillOptions.dotNET" />,
    value: 'net',
  },
  {
    key: '3d_animation',
    label: <FormattedMessage id="SkillOptions.3dAnimation" />,
    value: '3d_animation',
  },
  {
    key: '3d_design',
    label: <FormattedMessage id="SkillOptions.3dDesign" />,
    value: '3d_design',
  },
  {
    key: '3d_modeling',
    label: <FormattedMessage id="SkillOptions.3dModeling" />,
    value: '3d_modeling',
  },
  {
    key: '3d_rendering',
    label: <FormattedMessage id="SkillOptions.3dRendering" />,
    value: '3d_rendering',
  },
  {
    key: 'ab_testing',
    label: <FormattedMessage id="SkillOptions.aBTesting" />,
    value: 'ab_testing',
  },
  {
    key: 'activecampaign',
    label: <FormattedMessage id="SkillOptions.activeCampaign" />,
    value: 'activecampaign',
  },
  {
    key: 'adobe_after_effects',
    label: <FormattedMessage id="SkillOptions.adobeAfterEffects" />,
    value: 'adobe_after_effects',
  },
  {
    key: 'adobe_illustrator',
    label: <FormattedMessage id="SkillOptions.adobeIllustrator" />,
    value: 'adobe_illustrator',
  },
  {
    key: 'adobe_photoshop',
    label: <FormattedMessage id="SkillOptions.adobePhotoshop" />,
    value: 'adobe_photoshop',
  },
  {
    key: 'adobe_premiere_pro',
    label: <FormattedMessage id="SkillOptions.adobePremierePro" />,
    value: 'adobe_premiere_pro',
  },
  {
    key: 'advertising_copywriting',
    label: <FormattedMessage id="SkillOptions.advertisingCopywriting" />,
    value: 'advertising_copywriting',
  },
  {
    key: 'ahrefs',
    label: <FormattedMessage id="SkillOptions.ahrefs" />,
    value: 'ahrefs',
  },
  {
    key: 'ai_development',
    label: <FormattedMessage id="SkillOptions.aIDevelopment" />,
    value: 'ai_development',
  },
  {
    key: 'ai_image_production',
    label: <FormattedMessage id="SkillOptions.aIImageProduction" />,
    value: 'ai_image_production',
  },
  {
    key: 'ai_text_production',
    label: <FormattedMessage id="SkillOptions.aITextProduction" />,
    value: 'ai_text_production',
  },
  {
    key: 'ai_video_production',
    label: <FormattedMessage id="SkillOptions.aIVideoProduction" />,
    value: 'ai_video_production',
  },
  {
    key: 'airtable',
    label: <FormattedMessage id="SkillOptions.airtable" />,
    value: 'airtable',
  },
  {
    key: 'amazon_ads',
    label: <FormattedMessage id="SkillOptions.amazonAds" />,
    value: 'amazon_ads',
  },
  {
    key: 'android_development',
    label: <FormattedMessage id="SkillOptions.androidDevelopment" />,
    value: 'android_development',
  },
  {
    key: 'angular',
    label: <FormattedMessage id="SkillOptions.angular" />,
    value: 'angular',
  },
  {
    key: 'api_development',
    label: <FormattedMessage id="SkillOptions.aPIDevelopment" />,
    value: 'api_development',
  },
  {
    key: 'apolloio',
    label: <FormattedMessage id="SkillOptions.apolloio" />,
    value: 'apolloio',
  },
  {
    key: 'application_architecture',
    label: <FormattedMessage id="SkillOptions.applicationArchitecture" />,
    value: 'application_architecture',
  },
  {
    key: 'arvr_content_creation',
    label: <FormattedMessage id="SkillOptions.aRVRContentCreation" />,
    value: 'arvr_content_creation',
  },
  {
    key: 'article_writing',
    label: <FormattedMessage id="SkillOptions.articleWriting" />,
    value: 'article_writing',
  },
  {
    key: 'audio_editing',
    label: <FormattedMessage id="SkillOptions.audioEditing" />,
    value: 'audio_editing',
  },
  {
    key: 'audio_mastering',
    label: <FormattedMessage id="SkillOptions.audioMastering" />,
    value: 'audio_mastering',
  },
  {
    key: 'audio_mixing',
    label: <FormattedMessage id="SkillOptions.audioMixing" />,
    value: 'audio_mixing',
  },
  {
    key: 'augmented_reality',
    label: <FormattedMessage id="SkillOptions.augmentedReality" />,
    value: 'augmented_reality',
  },
  {
    key: 'autogpt',
    label: <FormattedMessage id="SkillOptions.autoGPT" />,
    value: 'autogpt',
  },
  {
    key: 'automations',
    label: <FormattedMessage id="SkillOptions.automations" />,
    value: 'automations',
  },
  {
    key: 'aws',
    label: <FormattedMessage id="SkillOptions.aws" />,
    value: 'aws',
  },
  {
    key: 'b2b_marketing',
    label: <FormattedMessage id="SkillOptions.b2bMarketing" />,
    value: 'b2b_marketing',
  },
  {
    key: 'b2b_sales',
    label: <FormattedMessage id="SkillOptions.b2bSales" />,
    value: 'b2b_sales',
  },
  {
    key: 'b2c_marketing',
    label: <FormattedMessage id="SkillOptions.b2cMarketing" />,
    value: 'b2c_marketing',
  },
  {
    key: 'back_end_development',
    label: <FormattedMessage id="SkillOptions.backEndDevelopment" />,
    value: 'back_end_development',
  },
  {
    key: 'bard',
    label: <FormattedMessage id="SkillOptions.bard" />,
    value: 'bard',
  },
  {
    key: 'big_data',
    label: <FormattedMessage id="SkillOptions.bigData" />,
    value: 'big_data',
  },
  {
    key: 'bing_ads',
    label: <FormattedMessage id="SkillOptions.bingAds" />,
    value: 'bing_ads',
  },
  {
    key: 'blender',
    label: <FormattedMessage id="SkillOptions.blender" />,
    value: 'blender',
  },
  {
    key: 'blockchain',
    label: <FormattedMessage id="SkillOptions.blockchain" />,
    value: 'blockchain',
  },
  {
    key: 'blog_writing',
    label: <FormattedMessage id="SkillOptions.blogWriting" />,
    value: 'blog_writing',
  },
  {
    key: 'brand_consultation',
    label: <FormattedMessage id="SkillOptions.brandConsultation" />,
    value: 'brand_consultation',
  },
  {
    key: 'brand_development',
    label: <FormattedMessage id="SkillOptions.brandDevelopment" />,
    value: 'brand_development',
  },
  {
    key: 'brand_management',
    label: <FormattedMessage id="SkillOptions.brandManagement" />,
    value: 'brand_management',
  },
  {
    key: 'brand_partnership',
    label: <FormattedMessage id="SkillOptions.brandPartnership" />,
    value: 'brand_partnership',
  },
  {
    key: 'brand_strategy',
    label: <FormattedMessage id="SkillOptions.brandStrategy" />,
    value: 'brand_strategy',
  },
  {
    key: 'branding',
    label: <FormattedMessage id="SkillOptions.branding" />,
    value: 'branding',
  },
  {
    key: 'brochure_design',
    label: <FormattedMessage id="SkillOptions.brochureDesign" />,
    value: 'brochure_design',
  },

  {
    key: 'business_card_design',
    label: <FormattedMessage id="SkillOptions.businessCardDesign" />,
    value: 'business_card_design',
  },
  {
    key: 'business_economics',
    label: <FormattedMessage id="SkillOptions.businessEconomics" />,
    value: 'business_economics',
  },
  {
    key: 'cad',
    label: <FormattedMessage id="SkillOptions.cAD" />,
    value: 'cad',
  },
  {
    key: 'character_design',
    label: <FormattedMessage id="SkillOptions.characterDesign" />,
    value: 'character_design',
  },
  {
    key: 'chatgpt',
    label: <FormattedMessage id="SkillOptions.chatGPT" />,
    value: 'chatgpt',
  },
  {
    key: 'color_correction',
    label: <FormattedMessage id="SkillOptions.colorCorrection" />,
    value: 'color_correction',
  },
  {
    key: 'commercial_photography',
    label: <FormattedMessage id="SkillOptions.commercialPhotography" />,
    value: 'commercial_photography',
  },
  {
    key: 'commercial_videos',
    label: <FormattedMessage id="SkillOptions.commercialVideos" />,
    value: 'commercial_videos',
  },
  {
    key: 'communication_strategy',
    label: <FormattedMessage id="SkillOptions.communicationStrategy" />,
    value: 'communication_strategy',
  },
  {
    key: 'computer_vision',
    label: <FormattedMessage id="SkillOptions.computerVision" />,
    value: 'computer_vision',
  },
  {
    key: 'concept_art',
    label: <FormattedMessage id="SkillOptions.conceptArt" />,
    value: 'concept_art',
  },
  {
    key: 'content_creation',
    label: <FormattedMessage id="SkillOptions.contentCreation" />,
    value: 'content_creation',
  },
  {
    key: 'content_marketing',
    label: <FormattedMessage id="SkillOptions.contentMarketing" />,
    value: 'content_marketing',
  },
  {
    key: 'conversion_rate_optimization',
    label: <FormattedMessage id="SkillOptions.conversionRateOptimization" />,
    value: 'conversion_rate_optimization',
  },
  {
    key: 'contentful',
    label: <FormattedMessage id="SkillOptions.contentful" />,
    value: 'contentful',
  },
  {
    key: 'cookie_management',
    label: <FormattedMessage id="SkillOptions.cookieManagement" />,
    value: 'cookie_management',
  },
  {
    key: 'copywriting',
    label: <FormattedMessage id="SkillOptions.copywriting" />,
    value: 'copywriting',
  },
  {
    key: 'corporate_economics',
    label: <FormattedMessage id="SkillOptions.corporateEconomics" />,
    value: 'corporate_economics',
  },
  {
    key: 'corporate_identity',
    label: <FormattedMessage id="SkillOptions.corporateIdentity" />,
    value: 'corporate_identity',
  },
  {
    key: 'corporate_video_production',
    label: <FormattedMessage id="SkillOptions.corporateVideoProduction" />,
    value: 'corporate_video_production',
  },
  {
    key: 'crisis_management',
    label: <FormattedMessage id="SkillOptions.crisisManagement" />,
    value: 'crisis_management',
  },
  {
    key: 'crm',
    label: <FormattedMessage id="SkillOptions.crm" />,
    value: 'crm',
  },
  {
    key: 'cross_platform_development',
    label: <FormattedMessage id="SkillOptions.crossPlatformDevelopment" />,
    value: 'cross_platform_development',
  },
  {
    key: 'customer_service',
    label: <FormattedMessage id="SkillOptions.customerService" />,
    value: 'customer_service',
  },
  {
    key: 'cybersecurity',
    label: <FormattedMessage id="SkillOptions.cybersecurity" />,
    value: 'cybersecurity',
  },
  {
    key: 'data_entry',
    label: <FormattedMessage id="SkillOptions.dataEntry" />,
    value: 'data_entry',
  },
  {
    key: 'data_science',
    label: <FormattedMessage id="SkillOptions.dataScience" />,
    value: 'data_science',
  },
  {
    key: 'database_management',
    label: <FormattedMessage id="SkillOptions.databaseManagement" />,
    value: 'database_management',
  },
  {
    key: 'deep_learning',
    label: <FormattedMessage id="SkillOptions.deepLearning" />,
    value: 'deep_learning',
  },
  {
    key: 'descript',
    label: <FormattedMessage id="SkillOptions.descript" />,
    value: 'descript',
  },
  {
    key: 'digital_illustration',
    label: <FormattedMessage id="SkillOptions.digitalIllustration" />,
    value: 'digital_illustration',
  },
  {
    key: 'digital_marketing',
    label: <FormattedMessage id="SkillOptions.digitalMarketing" />,
    value: 'digital_marketing',
  },
  {
    key: 'docker',
    label: <FormattedMessage id="SkillOptions.docker" />,
    value: 'docker',
  },
  {
    key: 'drone_videography',
    label: <FormattedMessage id="SkillOptions.droneVideography" />,
    value: 'drone_videography',
  },
  {
    key: 'dynamics_365',
    label: <FormattedMessage id="SkillOptions.dynamics365" />,
    value: 'dynamics_365',
  },
  {
    key: 'elastic_search',
    label: <FormattedMessage id="SkillOptions.elasticSearch" />,
    value: 'elastic_search',
  },
  {
    key: 'e_commerce_design',
    label: <FormattedMessage id="SkillOptions.eCommerceDesign" />,
    value: 'e_commerce_design',
  },
  {
    key: 'e_commerce_development',
    label: <FormattedMessage id="SkillOptions.eCommerceDevelopment" />,
    value: 'e_commerce_development',
  },
  {
    key: 'email_automation',
    label: <FormattedMessage id="SkillOptions.emailAutomation" />,
    value: 'email_automation',
  },
  {
    key: 'email_management',
    label: <FormattedMessage id="SkillOptions.emailManagement" />,
    value: 'email_management',
  },
  {
    key: 'email_marketing',
    label: <FormattedMessage id="SkillOptions.emailMarketing" />,
    value: 'email_marketing',
  },
  {
    key: 'employer_branding',
    label: <FormattedMessage id="SkillOptions.employerBranding" />,
    value: 'employer_branding',
  },
  {
    key: 'event_photography',
    label: <FormattedMessage id="SkillOptions.eventPhotography" />,
    value: 'event_photography',
  },
  {
    key: 'facebook_ads',
    label: <FormattedMessage id="SkillOptions.facebookAds" />,
    value: 'facebook_ads',
  },
  {
    key: 'figma',
    label: <FormattedMessage id="SkillOptions.figma" />,
    value: 'figma',
  },
  {
    key: 'final_cut_pro',
    label: <FormattedMessage id="SkillOptions.finalCutPro" />,
    value: 'final_cut_pro',
  },
  {
    key: 'financial_controlling',
    label: <FormattedMessage id="SkillOptions.financialControlling" />,
    value: 'financial_controlling',
  },
  {
    key: 'flutter',
    label: <FormattedMessage id="SkillOptions.flutter" />,
    value: 'flutter',
  },
  {
    key: 'flyer_design',
    label: <FormattedMessage id="SkillOptions.flyerDesign" />,
    value: 'flyer_design',
  },
  {
    key: 'front_end_development',
    label: <FormattedMessage id="SkillOptions.frontendDevelopment" />,
    value: 'front_end_development',
  },
  {
    key: 'ga4',
    label: <FormattedMessage id="SkillOptions.ga4" />,
    value: 'ga4',
  },
  {
    key: 'google_ads',
    label: <FormattedMessage id="SkillOptions.googleAds" />,
    value: 'google_ads',
  },
  {
    key: 'google_business_profiles',
    label: <FormattedMessage id="SkillOptions.googleBusinessProfiles" />,
    value: 'google_business_profiles',
  },
  {
    key: 'google_display_ads',
    label: <FormattedMessage id="SkillOptions.googleDisplayAds" />,
    value: 'google_display_ads',
  },
  {
    key: 'google_search_ads',
    label: <FormattedMessage id="SkillOptions.googleSearchAds" />,
    value: 'google_search_ads',
  },
  {
    key: 'google_shopping',
    label: <FormattedMessage id="SkillOptions.googleShopping" />,
    value: 'google_shopping',
  },
  {
    key: 'google_tag_manager',
    label: <FormattedMessage id="SkillOptions.googleTagManager" />,
    value: 'google_tag_manager',
  },
  {
    key: 'graphic_design',
    label: <FormattedMessage id="SkillOptions.graphicDesign" />,
    value: 'graphic_design',
  },
  {
    key: 'growth_hacking',
    label: <FormattedMessage id="SkillOptions.growthHacking" />,
    value: 'growth_hacking',
  },
  {
    key: 'hr_strategy',
    label: <FormattedMessage id="SkillOptions.hrStrategy" />,
    value: 'hr_strategy',
  },
  {
    key: 'hubspot',
    label: <FormattedMessage id="SkillOptions.hubSpot" />,
    value: 'hubspot',
  },
  {
    key: 'illustration',
    label: <FormattedMessage id="SkillOptions.illustration" />,
    value: 'illustration',
  },
  {
    key: 'image_retouching',
    label: <FormattedMessage id="SkillOptions.imageRetouching" />,
    value: 'image_retouching',
  },
  {
    key: 'inbound_marketing',
    label: <FormattedMessage id="SkillOptions.inboundMarketing" />,
    value: 'inbound_marketing',
  },
  {
    key: 'influencer_campaigns',
    label: <FormattedMessage id="SkillOptions.influencerCampaigns" />,
    value: 'influencer_campaigns',
  },
  {
    key: 'influencer_marketing',
    label: <FormattedMessage id="SkillOptions.influencerMarketing" />,
    value: 'influencer_marketing',
  },
  {
    key: 'infographics',
    label: <FormattedMessage id="SkillOptions.infographics" />,
    value: 'infographics',
  },
  {
    key: 'instagram_ads',
    label: <FormattedMessage id="SkillOptions.instagramAds" />,
    value: 'instagram_ads',
  },
  {
    key: 'instagram_marketing',
    label: <FormattedMessage id="SkillOptions.instagramMarketing" />,
    value: 'instagram_marketing',
  },
  {
    key: 'internal_communication',
    label: <FormattedMessage id="SkillOptions.internalCommunication" />,
    value: 'internal_communication',
  },
  {
    key: 'investor_relations',
    label: <FormattedMessage id="SkillOptions.investorRelations" />,
    value: 'investor_relations',
  },
  {
    key: 'ios_development',
    label: <FormattedMessage id="SkillOptions.iOSDevelopment" />,
    value: 'ios_development',
  },
  {
    key: 'java',
    label: <FormattedMessage id="SkillOptions.java" />,
    value: 'java',
  },
  {
    key: 'javascript',
    label: <FormattedMessage id="SkillOptions.javaScript" />,
    value: 'javascript',
  },
  {
    key: 'jenkins',
    label: <FormattedMessage id="SkillOptions.jenkins" />,
    value: 'jenkins',
  },
  {
    key: 'keyword_research',
    label: <FormattedMessage id="SkillOptions.keywordResearch" />,
    value: 'keyword_research',
  },
  {
    key: 'klaviyo',
    label: <FormattedMessage id="SkillOptions.klaviyo" />,
    value: 'klaviyo',
  },
  {
    key: 'kotlin',
    label: <FormattedMessage id="SkillOptions.kotlin" />,
    value: 'kotlin',
  },
  {
    key: 'kubernetes',
    label: <FormattedMessage id="SkillOptions.kubernetes" />,
    value: 'kubernetes',
  },
  {
    key: 'landing_page_design',
    label: <FormattedMessage id="SkillOptions.landingPageDesign" />,
    value: 'landing_page_design',
  },
  {
    key: 'landscape_photography',
    label: <FormattedMessage id="SkillOptions.landscapePhotography" />,
    value: 'landscape_photography',
  },
  {
    key: 'lead_generation',
    label: <FormattedMessage id="SkillOptions.leadGeneration" />,
    value: 'lead_generation',
  },
  {
    key: 'lightroom',
    label: <FormattedMessage id="SkillOptions.lightroom" />,
    value: 'lightroom',
  },
  {
    key: 'link_building',
    label: <FormattedMessage id="SkillOptions.linkBuilding" />,
    value: 'link_building',
  },
  {
    key: 'linkedin_ads',
    label: <FormattedMessage id="SkillOptions.linkedInAds" />,
    value: 'linkedin_ads',
  },
  {
    key: 'logo_design',
    label: <FormattedMessage id="SkillOptions.logoDesign" />,
    value: 'logo_design',
  },
  {
    key: 'looker_studio',
    label: <FormattedMessage id="SkillOptions.lookerStudio" />,
    value: 'looker_studio',
  },
  {
    key: 'machine_learning',
    label: <FormattedMessage id="SkillOptions.machineLearning" />,
    value: 'machine_learning',
  },
  {
    key: 'magento',
    label: <FormattedMessage id="SkillOptions.magento" />,
    value: 'magento',
  },
  {
    key: 'mailchimp',
    label: <FormattedMessage id="SkillOptions.mailChimp" />,
    value: 'mailchimp',
  },
  {
    key: 'management_accounting',
    label: <FormattedMessage id="SkillOptions.managementAccounting" />,
    value: 'management_accounting',
  },
  {
    key: 'marketing_automation',
    label: <FormattedMessage id="SkillOptions.marketingAutomation" />,
    value: 'marketing_automation',
  },
  {
    key: 'marketing_strategy',
    label: <FormattedMessage id="SkillOptions.marketingStrategy" />,
    value: 'marketing_strategy',
  },
  {
    key: 'media_relations',
    label: <FormattedMessage id="SkillOptions.mediaRelations" />,
    value: 'media_relations',
  },
  {
    key: 'meta_ads',
    label: <FormattedMessage id="SkillOptions.metaAds" />,
    value: 'meta_ads',
  },
  {
    key: 'microsof_azure',
    label: <FormattedMessage id="SkillOptions.microsoftAzure" />,
    value: 'microsof_azure',
  },
  {
    key: 'midjourney',
    label: <FormattedMessage id="SkillOptions.midjourney" />,
    value: 'midjourney',
  },
  {
    key: 'mobile_app_design',
    label: <FormattedMessage id="SkillOptions.mobileAppDesign" />,
    value: 'mobile_app_design',
  },
  {
    key: 'mobile_app_development',
    label: <FormattedMessage id="SkillOptions.mobileAppDevelopment" />,
    value: 'mobile_app_development',
  },
  {
    key: 'motion_graphics',
    label: <FormattedMessage id="SkillOptions.motionGraphics" />,
    value: 'motion_graphics',
  },
  {
    key: 'mycashflow',
    label: <FormattedMessage id="SkillOptions.myCashflow" />,
    value: 'mycashflow',
  },
  {
    key: 'n8n',
    label: <FormattedMessage id="SkillOptions.n8n" />,
    value: 'n8n',
  },
  {
    key: 'natural_language_processing',
    label: <FormattedMessage id="SkillOptions.naturalLanguageProcessing" />,
    value: 'natural_language_processing',
  },
  {
    key: 'neural_networks',
    label: <FormattedMessage id="SkillOptions.neuralNetworks" />,
    value: 'neural_networks',
  },
  {
    key: 'nodejs',
    label: <FormattedMessage id="SkillOptions.nodejs" />,
    value: 'nodejs',
  },
  {
    key: 'notion',
    label: <FormattedMessage id="SkillOptions.notion" />,
    value: 'notion',
  },
  {
    key: 'off_page_seo',
    label: <FormattedMessage id="SkillOptions.offPageSEO" />,
    value: 'off_page_seo',
  },
  {
    key: 'on_page_seo',
    label: <FormattedMessage id="SkillOptions.onPageSEO" />,
    value: 'on_page_seo',
  },
  {
    key: 'packaging_design',
    label: <FormattedMessage id="SkillOptions.packagingDesign" />,
    value: 'packaging_design',
  },
  {
    key: 'performance_max',
    label: <FormattedMessage id="SkillOptions.performanceMax" />,
    value: 'performance_max',
  },
  {
    key: 'photo_editing',
    label: <FormattedMessage id="SkillOptions.photoEditing" />,
    value: 'photo_editing',
  },
  {
    key: 'php',
    label: <FormattedMessage id="SkillOptions.php" />,
    value: 'php',
  },
  {
    key: 'pinterest_ads',
    label: <FormattedMessage id="SkillOptions.pinterestAds" />,
    value: 'pinterest_ads',
  },
  {
    key: 'podcast_production',
    label: <FormattedMessage id="SkillOptions.podcastProduction" />,
    value: 'podcast_production',
  },
  {
    key: 'portrait_photography',
    label: <FormattedMessage id="SkillOptions.portraitPhotography" />,
    value: 'portrait_photography',
  },
  {
    key: 'poster_design',
    label: <FormattedMessage id="SkillOptions.posterDesign" />,
    value: 'poster_design',
  },
  {
    key: 'power_platform',
    label: <FormattedMessage id="SkillOptions.powerPlatform" />,
    value: 'power_platform',
  },
  {
    key: 'print_design',
    label: <FormattedMessage id="SkillOptions.printDesign" />,
    value: 'print_design',
  },
  {
    key: 'product_description_writing',
    label: <FormattedMessage id="SkillOptions.productDescriptionWriting" />,
    value: 'product_description_writing',
  },
  {
    key: 'product_photography',
    label: <FormattedMessage id="SkillOptions.productPhotography" />,
    value: 'product_photography',
  },
  {
    key: 'project_management',
    label: <FormattedMessage id="SkillOptions.projectManagement" />,
    value: 'project_management',
  },
  {
    key: 'proofreading',
    label: <FormattedMessage id="SkillOptions.proofreading" />,
    value: 'proofreading',
  },
  {
    key: 'prospecting',
    label: <FormattedMessage id="SkillOptions.prospecting" />,
    value: 'prospecting',
  },
  {
    key: 'prototyping',
    label: <FormattedMessage id="SkillOptions.prototyping" />,
    value: 'prototyping',
  },
  {
    key: 'public_relations',
    label: <FormattedMessage id="SkillOptions.publicRelations" />,
    value: 'public_relations',
  },
  {
    key: 'python',
    label: <FormattedMessage id="SkillOptions.python" />,
    value: 'python',
  },
  {
    key: 'react',
    label: <FormattedMessage id="SkillOptions.react" />,
    value: 'react',
  },
  {
    key: 'react_native',
    label: <FormattedMessage id="SkillOptions.reactNative" />,
    value: 'react_native',
  },
  {
    key: 'real_estate_photography',
    label: <FormattedMessage id="SkillOptions.realEstatePhotography" />,
    value: 'real_estate_photography',
  },
  {
    key: 'recruitment',
    label: <FormattedMessage id="SkillOptions.recruitment" />,
    value: 'recruitment',
  },
  {
    key: 'replyio',
    label: <FormattedMessage id="SkillOptions.replyio" />,
    value: 'replyio',
  },
  {
    key: 'ruby',
    label: <FormattedMessage id="SkillOptions.ruby" />,
    value: 'ruby',
  },
  {
    key: 'sales_copywriting',
    label: <FormattedMessage id="SkillOptions.salesCopywriting" />,
    value: 'sales_copywriting',
  },
  {
    key: 'salesforce',
    label: <FormattedMessage id="SkillOptions.salesforce" />,
    value: 'salesforce',
  },
  {
    key: 'semrush',
    label: <FormattedMessage id="SkillOptions.semRush" />,
    value: 'semrush',
  },
  {
    key: 'seo',
    label: <FormattedMessage id="SkillOptions.seo" />,
    value: 'seo',
  },
  {
    key: 'seo_audit',
    label: <FormattedMessage id="SkillOptions.seoAudit" />,
    value: 'seo_audit',
  },
  {
    key: 'seo_strategy',
    label: <FormattedMessage id="SkillOptions.seoStrategy" />,
    value: 'seo_strategy',
  },
  {
    key: 'seo_writing',
    label: <FormattedMessage id="SkillOptions.seoWriting" />,
    value: 'seo_writing',
  },
  {
    key: 'shopify',
    label: <FormattedMessage id="SkillOptions.shopify" />,
    value: 'shopify',
  },
  {
    key: 'short_form_video_production',
    label: <FormattedMessage id="SkillOptions.shortFormVideoProduction" />,
    value: 'short_form_video_production',
  },
  {
    key: 'snapchat_ads',
    label: <FormattedMessage id="SkillOptions.snapchatAds" />,
    value: 'snapchat_ads',
  },
  {
    key: 'social_media_advertising',
    label: <FormattedMessage id="SkillOptions.socialMediaAdvertising" />,
    value: 'social_media_advertising',
  },
  {
    key: 'social_media_content',
    label: <FormattedMessage id="SkillOptions.socialMediaContent" />,
    value: 'social_media_content',
  },
  {
    key: 'social_media_marketing',
    label: <FormattedMessage id="SkillOptions.socialMediaMarketing" />,
    value: 'social_media_marketing',
  },
  {
    key: 'social_media_strategy',
    label: <FormattedMessage id="SkillOptions.socialMediaStrategy" />,
    value: 'social_media_strategy',
  },
  {
    key: 'social_media_video_creation',
    label: <FormattedMessage id="SkillOptions.socialMediaVideoCreation" />,
    value: 'social_media_video_creation',
  },
  {
    key: 'sound_design',
    label: <FormattedMessage id="SkillOptions.soundDesign" />,
    value: 'sound_design',
  },
  {
    key: 'sound_effects',
    label: <FormattedMessage id="SkillOptions.soundEffects" />,
    value: 'sound_effects',
  },
  {
    key: 'sql',
    label: <FormattedMessage id="SkillOptions.sql" />,
    value: 'sql',
  },
  {
    key: 'squarespace',
    label: <FormattedMessage id="SkillOptions.squarespace" />,
    value: 'squarespace',
  },
  {
    key: 'storytelling',
    label: <FormattedMessage id="SkillOptions.storytelling" />,
    value: 'storytelling',
  },
  {
    key: 'studio_photography',
    label: <FormattedMessage id="SkillOptions.studioPhotography" />,
    value: 'studio_photography',
  },
  {
    key: 'subtitles',
    label: <FormattedMessage id="SkillOptions.subtitles" />,
    value: 'subtitles',
  },
  {
    key: 'swift',
    label: <FormattedMessage id="SkillOptions.swift" />,
    value: 'swift',
  },
  {
    key: 'system_architecture',
    label: <FormattedMessage id="SkillOptions.systemArchitecture" />,
    value: 'system_architecture',
  },
  {
    key: 'technical_seo',
    label: <FormattedMessage id="SkillOptions.technicalSEO" />,
    value: 'technical_seo',
  },
  {
    key: 'tensorflow',
    label: <FormattedMessage id="SkillOptions.tensorFlow" />,
    value: 'tensorflow',
  },
  {
    key: 'tiktok_ads',
    label: <FormattedMessage id="SkillOptions.tikTokAds" />,
    value: 'tiktok_ads',
  },
  {
    key: 'transcription',
    label: <FormattedMessage id="SkillOptions.transcription" />,
    value: 'transcription',
  },
  {
    key: 'translation',
    label: <FormattedMessage id="SkillOptions.translation" />,
    value: 'translation',
  },
  {
    key: 'twitter_ads',
    label: <FormattedMessage id="SkillOptions.twitterAds" />,
    value: 'twitter_ads',
  },
  {
    key: 'typescript',
    label: <FormattedMessage id="SkillOptions.typeScript" />,
    value: 'typescript',
  },
  {
    key: 'typography',
    label: <FormattedMessage id="SkillOptions.typography" />,
    value: 'typography',
  },
  {
    key: 'ui_design',
    label: <FormattedMessage id="SkillOptions.uiDesign" />,
    value: 'ui_design',
  },
  {
    key: 'usability_testing',
    label: <FormattedMessage id="SkillOptions.usabilityTesting" />,
    value: 'usability_testing',
  },
  {
    key: 'user_experience_testing',
    label: <FormattedMessage id="SkillOptions.userExperienceTesting" />,
    value: 'user_experience_testing',
  },
  {
    key: 'user_feedback_analysis',
    label: <FormattedMessage id="SkillOptions.userFeedbackAnalysis" />,
    value: 'user_feedback_analysis',
  },
  {
    key: 'user_generated_content',
    label: <FormattedMessage id="SkillOptions.userGeneratedContent" />,
    value: 'user_generated_content',
  },
  {
    key: 'user_interface_testing',
    label: <FormattedMessage id="SkillOptions.userInterfaceTesting" />,
    value: 'user_interface_testing',
  },
  {
    key: 'user_research',
    label: <FormattedMessage id="SkillOptions.userResearch" />,
    value: 'user_research',
  },
  {
    key: 'user_testing',
    label: <FormattedMessage id="SkillOptions.userTesting" />,
    value: 'user_testing',
  },
  {
    key: 'ux_design',
    label: <FormattedMessage id="SkillOptions.uxDesign" />,
    value: 'ux_design',
  },
  {
    key: 'ux_writing',
    label: <FormattedMessage id="SkillOptions.uxWriting" />,
    value: 'ux_writing',
  },
  {
    key: 'vector_design',
    label: <FormattedMessage id="SkillOptions.vectorDesign" />,
    value: 'vector_design',
  },
  {
    key: 'video_editing',
    label: <FormattedMessage id="SkillOptions.videoEditing" />,
    value: 'video_editing',
  },
  {
    key: 'video_effects',
    label: <FormattedMessage id="SkillOptions.videoEffects" />,
    value: 'video_effects',
  },
  {
    key: 'video_production',
    label: <FormattedMessage id="SkillOptions.videoProduction" />,
    value: 'video_production',
  },
  {
    key: 'videography',
    label: <FormattedMessage id="SkillOptions.videography" />,
    value: 'videography',
  },
  {
    key: 'virtual_assistance',
    label: <FormattedMessage id="SkillOptions.virtualAssistance" />,
    value: 'virtual_assistance',
  },
  {
    key: 'virtual_reality',
    label: <FormattedMessage id="SkillOptions.virtualReality" />,
    value: 'virtual_reality',
  },
  {
    key: 'voice_over',
    label: <FormattedMessage id="SkillOptions.voiceOver" />,
    value: 'voice_over',
  },
  {
    key: 'vuejs',
    label: <FormattedMessage id="SkillOptions.vuejs" />,
    value: 'vuejs',
  },
  {
    key: 'web_analytics',
    label: <FormattedMessage id="SkillOptions.webAnalytics" />,
    value: 'web_analytics',
  },
  {
    key: 'web_analytics',
    label: <FormattedMessage id="SkillOptions.webAnalytics" />,
    value: 'web_analytics',
  },
  {
    key: 'web_development',
    label: <FormattedMessage id="SkillOptions.webDevelopment" />,
    value: 'web_development',
  },
  {
    key: 'webflow',
    label: <FormattedMessage id="SkillOptions.webflow" />,
    value: 'webflow',
  },
  {
    key: 'website_design',
    label: <FormattedMessage id="SkillOptions.websiteDesign" />,
    value: 'website_design',
  },
  {
    key: 'wireframing',
    label: <FormattedMessage id="SkillOptions.wireframing" />,
    value: 'wireframing',
  },
  {
    key: 'wix',
    label: <FormattedMessage id="SkillOptions.wix" />,
    value: 'wix',
  },
  {
    key: 'woocommerce',
    label: <FormattedMessage id="SkillOptions.wooCommerce" />,
    value: 'woocommerce',
  },
  {
    key: 'wordpress',
    label: <FormattedMessage id="SkillOptions.wordPress" />,
    value: 'wordpress',
  },
  {
    key: 'zapier',
    label: <FormattedMessage id="SkillOptions.zapier" />,
    value: 'zapier',
  },
];

export const skillLevelOptions = [
  {
    key: 'entry',
    label: 'SkillLevelOptions.entry',
    value: 'entry',
  },
  {
    key: 'junior',
    label: 'SkillLevelOptions.junior',
    value: 'junior',
  },
  {
    key: 'midlevel',
    label: 'SkillLevelOptions.midlevel',
    value: 'midlevel',
  },
  {
    key: 'senior',
    label: 'SkillLevelOptions.senior',
    value: 'senior',
  },
];

export const languageLevelOptions = [
  {
    key: 'basic',
    label: 'LanguageLevelOptions.basic',
    value: 'basic',
  },
  {
    key: 'conversational',
    label: 'LanguageLevelOptions.conversational',
    value: 'conversational',
  },
  {
    key: 'fluent',
    label: 'LanguageLevelOptions.fluent',
    value: 'fluent',
  },
  {
    key: 'native',
    label: 'LanguageLevelOptions.native',
    value: 'native',
  },
];
