import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape, FormattedDate } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  FieldTextInput,
  Form,
  FieldCurrencyInput,
  PrimaryButton,
  FieldDateInput,
  InfoIcon,
  IconEnquiry,
} from '../../components';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';

import css from './CustomOfferForm.css';
import config from '../../config';
import { composeValidators, required } from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const BLUR_TIMEOUT_MS = 100;
const VAT_FACTOR = 125.5;

class CustomOfferFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            isProvider,
            customOfferAmount,
            customOfferPlusVATAmount,
            customOfferVATAmount,
            customOfferDescription,
            customOfferDeliveryDate,
            handleSubmit,
            inProgress,
            customOfferError,
            invalid,
            form,
            formId,
            intl,
            values,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;

          const descriptionPlaceholderMessage = intl.formatMessage({
            id: 'CustomOfferForm.descriptionPlaceholder',
          });
          const descriptionRequiredMessage = intl.formatMessage({
            id: 'CustomOfferForm.descriptionRequired',
          });

          const amountLabel = intl.formatMessage({ id: 'CustomOfferForm.amountLabel' });
          const amountPlaceholder = intl.formatMessage({ id: 'CustomOfferForm.amountPlaceholder' });
          const amountRequiredMessage = intl.formatMessage({
            id: 'CustomOfferForm.amountRequired',
          });

          const deliveryDatePlaceHolder = intl.formatMessage({
            id: 'CustomOfferForm.deliveryDatePlaceholder',
          });

          const deliveryDateRequiredMessage = intl.formatMessage({
            id: 'CustomOfferForm.deliveryDateRequired',
          });

          const deliveryDateLabel = intl.formatMessage({
            id: 'ProjectBidForm.deliveryDateLabel',
          });

          const totalPriceLabel = intl.formatMessage({ id: 'CustomOfferForm.totalPriceLabel' });
          const totalPricePlaceholder = intl.formatMessage({
            id: 'CustomOfferForm.totalPricePlaceholder',
          });

          const taxedAmount = Math.round((values.customOfferAmount?.amount * VAT_FACTOR) / 100);
          const totalPrice = new Money(taxedAmount, 'EUR');

          return isProvider ? (
            <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
              <IconEnquiry className={css.icon} />
              <div className={css.titleBlock}>
                <h2 className={css.heading}>
                  <FormattedMessage id="ProjectBidForm.heading" />
                </h2>
                <InfoIcon
                  className={css.infoIcon}
                  data-tip={intl.formatMessage({ id: 'ProjectBidForm.headingTooltip' })}
                  data-border={true}
                  data-text-color={'black'}
                  data-background-color={'white'}
                  data-border-color={'darkgrey'}
                  data-class={css.tooltipText}
                  data-place={'bottom'}
                />
              </div>
              <FieldTextInput
                id="description"
                name="description"
                className={css.field}
                type="textarea"
                label="Tarjouksen sisältö"
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />

              <FieldCurrencyInput
                className={css.field}
                inputrootclass={css.field}
                id={formId ? `${formId}.customOfferAmount` : 'customOfferAmount'}
                name="customOfferAmount"
                currencyConfig={config.currencyConfig}
                label={amountLabel}
                placeholder={amountPlaceholder}
                validate={required(amountRequiredMessage)}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />

              <div className={css.field}>
                <label>{totalPriceLabel}</label>
                <div className={css.totalPrice}>
                  {values.customOfferAmount ? (
                    <span>{formatMoney(intl, totalPrice)}</span>
                  ) : (
                    <span className={css.totalPricePlaceholder}>{totalPricePlaceholder}</span>
                  )}
                </div>
              </div>

              <FieldDateInput
                id="deliveryDate"
                name="deliveryDate"
                label={deliveryDateLabel}
                className={css.field}
                placeholderText={deliveryDatePlaceHolder}
                validate={required(deliveryDateRequiredMessage)}
              />

              <div className={css.offerSubmitContainer}>
                <div>
                  {customOfferError ? (
                    <p className={css.error}>
                      <FormattedMessage id="CustomOfferForm.operationFailed" />
                    </p>
                  ) : null}
                </div>
                <PrimaryButton
                  rootClassName={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                >
                  <FormattedMessage id="CustomOfferForm.makeAnOffer" />
                </PrimaryButton>
              </div>
            </Form>
          ) : (
            <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
              <div className={css.customOfferDescription}>
                <FormattedMessage id="CustomOfferForm.customOfferDescription" />
              </div>

              <div className={css.deliveryContainer}>
                <FormattedMessage id="CustomOfferForm.deliveryDate" />
                <FormattedDate value={customOfferDeliveryDate} />
              </div>
              <div className={css.submitContainer}>
                <div className={css.customOfferPrice}>
                  <FormattedMessage id="CustomOfferForm.priceLabel" />
                  &nbsp;
                  {customOfferAmount ? formatMoney(intl, customOfferPlusVATAmount) : null}
                </div>
                <div className={css.customOfferVAT}>
                  <FormattedMessage id="CustomOfferForm.bidAmountVAT" />
                  &nbsp;
                  {customOfferAmount ? formatMoney(intl, customOfferVATAmount) : null}
                </div>
                <div className={css.customOfferService}>
                  <FormattedMessage id="CustomOfferForm.serviceLabel" />
                  &nbsp;
                  <div className={css.customOfferServiceAmount}>
                    {customOfferAmount ? formatMoney(intl, customOfferAmount) : null}
                  </div>
                </div>
                <div className={css.customOfferDescriptionContent}>{customOfferDescription}</div>
                <div className={css.errorContainer}>
                  {customOfferError ? (
                    <p className={css.error}>
                      <FormattedMessage id="CustomOfferForm.operationFailed" />
                    </p>
                  ) : null}
                </div>
                <PrimaryButton
                  rootClassName={css.submitButtonAccept}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                >
                  <FormattedMessage id="CustomOfferForm.acceptAnOffer" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

CustomOfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

CustomOfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CustomOfferForm = compose(injectIntl)(CustomOfferFormComponent);

CustomOfferForm.displayName = 'CustomOfferForm';

export default CustomOfferForm;
