import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  NamedLink,
} from '../../components';

import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SuccessStoriesPage.css';

import zervantImage from '../../assets/customer-story-zervant-hero.webp';
import tulusImage from '../../assets/customer-story-tulus-hero.webp';
import piirlaImage from '../../assets/piirla-success-story-banner.webp';
import coregoImage from '../../assets/corego-success-story-banner.webp';
import systamImage from '../../assets/systam-success-story-banner.webp';
import eerikkilaImage from '../../assets/eerikkila-success-story-banner.webp';

import { indexOf } from 'lodash';

export const SuccessStoriesPageComponent = props => {
  const { intl, scrollingDisabled } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const schemaTitle = intl.formatMessage({ id: 'SuccessStoriesPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({ id: 'SuccessStoriesPage.schemaDescription' });

  const successStoriesArray = [
    {
      title: <FormattedMessage id="SuccessStoriesPage.eerikkilaXfreedomly" />,
      description: <FormattedMessage id="SuccessStoriesPage.cardTextEerikkila" />,
      image: eerikkilaImage,
      page: 'SuccessStoryEerikkilaPage',
    },
    {
      title: <FormattedMessage id="SuccessStoriesPage.systamXfreedomly" />,
      description: <FormattedMessage id="SuccessStoriesPage.cardTextSystam" />,
      image: systamImage,
      page: 'SuccessStorySystamPage',
    },
    {
      title: <FormattedMessage id="SuccessStoriesPage.coregoXfreedomly" />,
      description: <FormattedMessage id="SuccessStoriesPage.cardTextCorego" />,
      image: coregoImage,
      page: 'SuccessStoryCoregoPage',
    },
    {
      title: <FormattedMessage id="SuccessStoriesPage.piirlaXfreedomly" />,
      description: <FormattedMessage id="SuccessStoriesPage.cardTextPiirla" />,
      image: piirlaImage,
      page: 'SuccessStoryPiirlaPage',
    },
    {
      title: <FormattedMessage id="SuccessStoriesPage.tulusXfreedomly" />,
      description: <FormattedMessage id="SuccessStoriesPage.cardTextTulus" />,
      image: tulusImage,
      page: 'SuccessStoryTulusPage',
    },
    {
      title: <FormattedMessage id="SuccessStoriesPage.zervantXfreedomly" />,
      description: <FormattedMessage id="SuccessStoriesPage.cardTextZervant" />,
      image: zervantImage,
      page: 'SuccessStoryZervantPage',
    },
  ];

  const SuccessStoryCard = props => {
    const { title, description, image, page } = props;

    return (
      <NamedLink name={page} className={css.cardLink}>
        <div className={css.cardImage}>
          <img src={image} alt={title} />
        </div>
        <div className={css.cardText}>
          <div className={css.cardTitle}>{title}</div>
          <div className={css.cardDescription}>{description}</div>
        </div>
      </NamedLink>
    );
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="SuccessStoriesPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroSection}>
            <div className={css.heroSectionTitle}>
              <FormattedMessage id="SuccessStoriesPage.heroTitle" />
            </div>
            <div className={css.heroSectionSubtitle}>
              <FormattedMessage id="SuccessStoriesPage.heroSubtitle" />
            </div>
          </div>
          <div className={css.successStoryCards}>
            {successStoriesArray.map(story => (
              <SuccessStoryCard
                title={story.title}
                description={story.description}
                image={story.image}
                page={story.page}
                key={indexOf(successStoriesArray, story)}
              />
            ))}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SuccessStoriesPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(SuccessStoriesPageComponent);

export default SuccessStoriesPage;
